import { Outlet } from "react-router-dom";
import { Sidebar } from "../../shared/components/sidebar";
import { Header } from "../../shared/components/header";
import { MainContent } from "../../shared/components/main-content";

export const AuthPageComponent = () => {
  return (
    <section>
      <div className="flex">
        <Sidebar />
        <div className="flex-1">
          <Header />
          <MainContent
            className="bg-lex-white"
            style={{ height: "calc(100vh - 116px)" }}
          >
            <div className="p-6 max-h-full overflow-auto h-full">
              <Outlet />
            </div>
          </MainContent>
        </div>
      </div>
      <footer className="relative bg-lex-primary text-black w-full flex justify-center py-2 text-sm items-center z-[9999]">
        <span className="font-semibold">
          © 2024 Hootzpá. Todos os direitos reservados. Lexyia é uma marca
          registrada de Hootzpá.
        </span>
      </footer>
    </section>
  );
};
