import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  SvgIcon,
} from "@mui/material";

import { ReactComponent as DeleteIcon } from "../../shared/assets/icons/trash.svg";
import { ReactComponent as ViewIcon } from "../../shared/assets/icons/eye.svg";

import DOMPurify from "dompurify";
import { ContractData } from "../../shared/models/contract.model";
import { ReportModel } from "../../shared/models/register.models";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { ConfirmationModal } from "../../shared/components/confirmation-modal";
import apiBase from "../../shared/services/api";
import { ErrorModal } from "../../shared/components/error-modal";
import { LoadingComponent } from "../../shared/components/utils/loading-component";

export const ReportsTable: React.FC<{
  reportsDatas: ReportModel[];
  onRefresh: () => void;
}> = ({ reportsDatas, onRefresh }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [reportSelected, setReportSelected] = useState<
    ReportModel | undefined
  >();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenConfirmModal = (reportSelected: ReportModel) => {
    setReportSelected(reportSelected);
    setConfirmModalOpen(true);
  };
  const handleCloseConfirmModal = () => {
    setConfirmModalOpen(false);
  };

  const [viewModalOpen, setViewModalOpen] = useState(false);

  const handleViewOpenModal = (reportSelected: ReportModel) => {
    navigate(`/app/report-resume/${reportSelected.id}`);
    // setViewModalOpen(true);
  };

  const handleViewCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleConfirmModal = async () => {
    handleCloseConfirmModal();
    setLoading(true);
    await apiBase
      .delete(`/report/${reportSelected?.id}`)
      .then(() => {
        onRefresh();
        setLoading(false);
        setError("");
      })
      .catch((error: any) => {
        setLoading(false);
        setError(error.message || "Erro ao criar relatorio");
      });
  };

  return (
    <section>
      {loading && <LoadingComponent />}
      <ErrorModal
        open={!!error}
        onClose={() => setError("")}
        errorMessage={error}
      />
      <ConfirmationModal
        open={confirmModalOpen}
        onClose={handleCloseConfirmModal}
        onConfirm={() => handleConfirmModal()}
      />
      <TableContainer component={Paper} elevation={0}>
        <Table className="shadow-none">
          <TableHead>
            <TableRow>
              <TableCell className="font-semibold pl-0">
                Nome do Relatório
              </TableCell>
              <TableCell className="font-semibold">Data</TableCell>
              <TableCell className="font-semibold ">
                <div className="flex justify-center w-full">Excluir</div>
              </TableCell>
              <TableCell className="font-semibold">
                <div className="flex justify-center w-full">Ver Relatório</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {reportsDatas.map((report, index) => {
              const last = reportsDatas.length === index + 1;
              return (
                <TableRow key={report.id}>
                  <TableCell
                    className="py-1 pl-0"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {report.name}
                  </TableCell>
                  <TableCell
                    className="py-1 pl-0"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {format(new Date(report.createdAt), "dd/MM/yyyy")}
                  </TableCell>
                  <TableCell
                    className="py-1 "
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <div className="flex justify-center w-full">
                      <IconButton className="px-0 " color="error">
                        <SvgIcon onClick={() => handleOpenConfirmModal(report)}>
                          <DeleteIcon />
                        </SvgIcon>
                      </IconButton>{" "}
                    </div>
                  </TableCell>
                  <TableCell
                    className="py-1 "
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <div className="flex justify-center w-full">
                      <IconButton className="px-0 ">
                        <SvgIcon onClick={() => handleViewOpenModal(report)}>
                          <ViewIcon />
                        </SvgIcon>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};
