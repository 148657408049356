import { Button } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";
import { LoginComponent } from "../pages/login/login";
import { PrivateRoute } from "./privateRoute";
import { AuthPageComponent } from "../pages/auth/auth";
import { NewReport } from "../pages/auth/new-report";
import { AddNewContract } from "../pages/auth/add-new-contract";
import { UploadContract } from "../pages/auth/upload-contract";
import { ReportResume } from "../pages/auth/report-resume";
import { RegisterComponent } from "../pages/login/register";
import { Reports } from "../pages/auth/reports";
import { MyProvider } from "../shared/contexts/socket-context";

export const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/register" element={<RegisterComponent />}></Route>
      <Route path="/login" element={<LoginComponent />}></Route>
      <Route
        path="/app/*"
        element={
          <PrivateRoute>
            <MyProvider>
              <AuthPageComponent />
            </MyProvider>
          </PrivateRoute>
        }
      >
        <Route path="reports" element={<Reports />} />
        <Route path="create-report" element={<NewReport />} />
        <Route path="create-contract/:reportId" element={<AddNewContract />} />
        <Route
          path="create-contract/:reportId/upload-contract/:categoryId/:typeId"
          element={<UploadContract />}
        />
        <Route
          path="create-contract/:reportId/upload-contract/:categoryId"
          element={<UploadContract />}
        />
        <Route path="report-resume/:reportId" element={<ReportResume />} />
        <Route
          path="*"
          element={<Navigate to="reports" relative="route" />}
        ></Route>
      </Route>
      <Route path="*" element={<Navigate to="/login" />}></Route>
    </Routes>
  );
};
