export const BreadcrumbComponent: React.FC<{ breadcrumbs: string[] }> = ({
  breadcrumbs,
}) => {
  return (
    <section className="px-3 flex bg-white my-6 border border-solid rounded border-lex-white-2 w-fit">
      {breadcrumbs.map((item, index) => (
        <div
          key={index}
          className={`${
            index + 1 !== breadcrumbs.length
              ? "text-lex-white-2"
              : "text-lex-gray-3"
          } flex  px-2 items-center overflow-y-hidden font-medium`}
        >
          <span className="py-2 text-sm">{item}</span>
          {index + 1 < breadcrumbs.length && (
            <div className=" border-r border-b border-solid border-lex-gray-2 h-7 w-7 -rotate-45"></div>
          )}
        </div>
      ))}
    </section>
  );
};
