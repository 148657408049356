import { Button, CircularProgress, SvgIcon } from "@mui/material";
import logo from "../../shared/assets/images/logo.svg";
import { ReactComponent as EmailIcon } from "../../shared/assets/icons/email-icon.svg";
import { ReactComponent as LockIcon } from "../../shared/assets/icons/lock-icon.svg";
import { ReactComponent as VisibilityIcon } from "../../shared/assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../shared/assets/icons/visibility-off.svg";
import { ReactComponent as PeopleIcon } from "../../shared/assets/icons/people.svg";
import { ReactComponent as ArrowIcon } from "../../shared/assets/icons/arrow-left.svg";
import { ReactComponent as ScanIcon } from "../../shared/assets/icons/scan.svg";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ErrorModal } from "../../shared/components/error-modal";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { RegisterFeedbackModal } from "./components/register-feedback-modal";
import apiBase from "../../shared/services/api";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Campo obrigatório"),
  lastName: Yup.string().required("Campo obrigatório"),
  email: Yup.string().email("Email inválido").required("Campo obrigatório"),
  password: Yup.string()
    .required("Campo obrigatório")
    .min(8, "Senha deve ter pelo menos 8 caracteres"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password")], "Senhas precisam ser iguais")
    .required("Campo obrigatório"),
  access_code: Yup.string()
  .required("Campo obrigatório"),
});

export const RegisterComponent = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState<string>("");
  const navigate = useNavigate();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openRegisterFeedbackModal, setOpenRegisterFeedbackModal] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  //   const handleChange = (e: any) => {
  //     const { name, value } = e.target;
  //     setFormData({
  //       ...formData,
  //       [name]: value,
  //     });
  //   };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleOpenErrorModal = (message: string) => {
    setError(message);
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };
  const handleCloseRegisterFeedbackModal = () => {
    setOpenRegisterFeedbackModal(false);
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleLogin = async (
    formData: {
      name: string;
      lastName: string;
      email: string;
      password: string;
      confirmPassword: string;
      access_code: string;
    },
    validateForm: Function
  ) => {
    setIsLoading(true);
    // validationSchema.isValid()
    // await login(formData.email, formData.password);
    // Redirecionar para outra página, por exemplo, a página inicial
    // const errors = await validateForm();
    // if (Object.keys(errors).length > 0) {
    //   alert(JSON.stringify(errors, null, 2));
    //   return;
    // }
    await apiBase
      .post("/user", formData)
      .then(() => {
        // localStorage.setItem("token", response.data.token);
        setIsLoading(false);
        setOpenRegisterFeedbackModal(true);
      })
      .catch((error: any) => {
        setIsLoading(false);
        handleOpenErrorModal(error.message || "Erro ao criar conta");
      });

    // localStorage.setItem("token", response.data.token);
    // setIsLoading(false);
  };

  return (
    <section className="flex w-full h-full">
      {isLoading && (
        <div className="absolute h-screen w-screen flex justify-center items-center bg-lex-gray-3 bg-opacity-50">
          <CircularProgress
            className="text-lex-primary !w-52 !h-52"
            color="primary"
          />
        </div>
      )}
      <ErrorModal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        errorMessage={errorMessage}
      />
      <RegisterFeedbackModal
        open={openRegisterFeedbackModal}
        onClose={handleCloseRegisterFeedbackModal}
      />
      <div className="w-full flex justify-center h-screen md:max-w-[700px] md:h-auto items-center">
        <div className="w-96">
          <img src={logo} alt="Logo" className="mb-8" />
          <Link to="/login" className="text-lex-primary text-lg font-semibold">
            <SvgIcon className="mr-1">
              <ArrowIcon />
            </SvgIcon>
            Voltar
          </Link>
          <h1 className="text-lex-primary text-3xl font-bold pt-4 pb-2">
            Crie sua conta
          </h1>
          <span className="text-lg">
            Preencha o cadastro para ter acesso a
            <span className="font-bold"> Lexyia</span>.
          </span>
          <Formik
            initialValues={{
              name: "",
              lastName: "",
              email: "",
              password: "",
              confirmPassword: "",
              access_code: ""
            }}
            validationSchema={validationSchema}
            onSubmit={(values, { validateForm }) =>
              handleLogin(values, validateForm)
            }
          >
            {({ errors, touched, handleChange }: any) => (
              <Form>
                <div
                  className={`flex border border-solid ${
                    errors.name && touched.name
                      ? "border-lex-red"
                      : "border-lex-gray-2"
                  } rounded w-96 items-center p-2 bg-lex-white mt-8 mb-3`}
                >
                  <SvgIcon className="mr-1">
                    <PeopleIcon
                      className={
                        errors.name && touched.name
                          ? "fill-lex-red"
                          : "fill-lex-gray-2"
                      }
                    />
                  </SvgIcon>
                  <div className="flex flex-col text-lex-gray-3 w-full">
                    <label className="text-xs">Nome</label>
                    <Field
                      placeholder="Digite seu nome"
                      className="focus-within:outline-none text-base font-semibold bg-lex-white w-full"
                      type="text"
                      name="name"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div
                  className={`flex border border-solid ${
                    errors.lastName && touched.lastName
                      ? "border-lex-red"
                      : "border-lex-gray-2"
                  } rounded w-96 items-center p-2 bg-lex-white  mb-3`}
                >
                  <SvgIcon className="mr-1">
                    <PeopleIcon
                      className={
                        errors.lastName && touched.lastName
                          ? "fill-lex-red"
                          : "fill-lex-gray-2"
                      }
                    />
                  </SvgIcon>
                  <div className="flex flex-col text-lex-gray-3 w-full">
                    <label className="text-xs">Sobenome</label>
                    <Field
                      placeholder="Digite seu sobrenome"
                      className="focus-within:outline-none text-base font-semibold bg-lex-white w-full"
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div
                  className={`flex border border-solid ${
                    errors.email && touched.email
                      ? "border-lex-red"
                      : "border-lex-gray-2"
                  } rounded w-96 items-center p-2 bg-lex-white  mb-3`}
                >
                  <SvgIcon className="mr-1">
                    <EmailIcon
                      className={
                        errors.email && touched.email
                          ? "fill-lex-red"
                          : "fill-lex-gray-2"
                      }
                    />
                  </SvgIcon>
                  <div className="flex flex-col text-lex-gray-3 w-full">
                    <label className="text-xs">E-mail</label>
                    <Field
                      placeholder="Digite seu e-mail"
                      className="focus-within:outline-none text-base font-semibold bg-lex-white w-full"
                      type="email"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div
                  className={`flex border border-solid ${
                    errors.password && touched.password
                      ? "border-lex-red"
                      : "border-lex-gray-2"
                  } rounded w-96 items-center p-2 bg-lex-white  mb-3`}
                >
                  <SvgIcon className="mr-1">
                    <LockIcon
                      className={
                        errors.password && touched.password
                          ? "fill-lex-red"
                          : "fill-lex-gray-2"
                      }
                    />
                  </SvgIcon>
                  <div className="flex flex-col text-lex-gray-3 w-full">
                    <label className="text-xs">Senha</label>
                    <Field
                      placeholder="Digite a sua senha(min 8 caracteres)"
                      className="focus-within:outline-none text-base font-semibold bg-lex-white"
                      type={showPassword ? "text" : "password"}
                      name="password"
                      onChange={handleChange}
                    />
                  </div>
                  <SvgIcon
                    className="mx-1 cursor-pointer"
                    onClick={togglePasswordVisibility}
                  >
                    {showPassword ? (
                      <VisibilityOffIcon
                        className={
                          errors.password && touched.password
                            ? "fill-lex-red"
                            : "fill-lex-gray-2"
                        }
                      />
                    ) : (
                      <VisibilityIcon
                        className={
                          errors.password && touched.password
                            ? "fill-lex-red"
                            : "fill-lex-gray-2"
                        }
                      />
                    )}
                  </SvgIcon>
                </div>
                <div
                  className={`flex border border-solid ${
                    errors.confirmPassword && touched.confirmPassword
                      ? "border-lex-red"
                      : "border-lex-gray-2"
                  } rounded w-96 items-center p-2 bg-lex-white  mb-3`}
                >
                  <SvgIcon className="mr-1">
                    <LockIcon
                      className={
                        errors.confirmPassword && touched.confirmPassword
                          ? "fill-lex-red"
                          : "fill-lex-gray-2"
                      }
                    />
                  </SvgIcon>
                  <div className="flex flex-col text-lex-gray-3 w-full">
                    <label className="text-xs">Senha</label>
                    <Field
                      placeholder="Confirme a sua senha"
                      className="focus-within:outline-none text-base font-semibold bg-lex-white"
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      onChange={handleChange}
                    />
                  </div>
                  <SvgIcon
                    className="mx-1 cursor-pointer"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    {showConfirmPassword ? (
                      <VisibilityOffIcon
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "fill-lex-red"
                            : "fill-lex-gray-2"
                        }
                      />
                    ) : (
                      <VisibilityIcon
                        className={
                          errors.confirmPassword && touched.confirmPassword
                            ? "fill-lex-red"
                            : "fill-lex-gray-2"
                        }
                      />
                    )}
                  </SvgIcon>
                  
                </div>
                <div
                  className={`flex border border-solid ${
                    errors.access_code && touched.access_code
                      ? "border-lex-red"
                      : "border-lex-gray-2"
                  } rounded w-96 items-center p-2 bg-lex-white mt-8 mb-3`}
                >
                  <SvgIcon className="mr-1">
                    <ScanIcon
                      className={
                        errors.access_code && touched.access_code
                          ? "fill-lex-red"
                          : "fill-lex-gray-2"
                      }
                    />
                  </SvgIcon>
                  <div className="flex flex-col text-lex-gray-3 w-full">
                    <label className="text-xs">Código de Acesso</label>
                    <Field
                      placeholder="Digite o código de acesso"
                      className="focus-within:outline-none text-base font-semibold bg-lex-white w-full"
                      type="text"
                      name="access_code"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <button
                  type="submit"
                  className="border border-solid border-lex-gray-2 w-full text-lex-gray-3 py-3 px-4 text-center rounded ease-out duration-300 hover:bg-lex-primary hover:border-lex-primary hover:font-bold mt-4"
                >
                  Cadastrar
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className="hidden md:flex w-full bg-login bg-center h-screen bg-no-repeat bg-cover"></div>
    </section>
  );
};
