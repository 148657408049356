import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Modal,
  SvgIcon,
} from "@mui/material";
import { ReactComponent as CloseIcon } from "../assets/icons/x-circle.svg";
import { ReactComponent as DeleteIcon } from "../assets/icons/trash-white.svg";
import { ReactComponent as PdfIcon } from "../assets/icons/file-pdf-white.svg";
import { ReactComponent as DocIcon } from "../assets/icons/file-doc-white.svg";
import { ReactComponent as PencilIcon } from "../assets/icons/pencil-line.svg";
import { BorderInnerRounded, Padding, SaveAs } from "@mui/icons-material";
import DOMPurify from "dompurify";
import PdfDocument from "./pdf-utils";
import { Document, HeadingLevel, Packer, Paragraph, TextRun } from "docx";
import { PDFDownloadLink } from "@react-pdf/renderer";
import FileSaver from "file-saver";
import { useEffect, useRef, useState } from "react";
import { ErrorModal } from "./error-modal";
import { ConfirmationModal } from "./confirmation-modal";
import { ContractData } from "../models/contract.model";
import { FileChild } from "docx/build/file/file-child";
import { PdfAndDocFormat } from "../services/utils.service";
interface ViewContractModalProps {
  open: boolean;
  onClose: () => void;
  contractData: ContractData | undefined;
  categoryName: string;
  onDeleteContract: () => void;
  onRefresh: (contractId: number, file: File) => void;
}

export const ViewContractModal: React.FC<ViewContractModalProps> = ({
  open,
  onClose,
  contractData,
  categoryName,
  onDeleteContract,
  onRefresh,
}) => {
  //   let html2 = `<div
  //   style="padding: 1rem; display: flex; flex-direction: column; align-items: start; font-family: 'Inter', sans-serif;"
  // >
  //   <h1 style="font-size: 24px; font-weight: 700; padding-bottom: 0.5rem;">
  //     CategoryName
  //   </h1>
  //   <h2 style="font-size: 18px; font-weight: 600; padding-bottom: 0.25rem;">
  //     ContractName
  //   </h2>
  //   {fields}
  // </div>`;
  //   let fields = "";
  //   contractData?.fields.forEach((field, index) => {
  //     fields += `<div style="display: flex; font-size: 14px !important; gap: 0.5rem;">
  //     <span style="font-weight: 600;">${index + 1} -</span>
  //     <div style="gap: 0.5rem;">
  //       <h3 style="font-weight: 600;">${field.name}</h3>
  //       <p style="font-weight: 400;">
  //       ${field.value}
  //       </p>
  //     </div>
  //   </div>`;
  //   });
  const [htmlContent, setHtmlContent] = useState<string>("");
  const sanitizedHtmlContent = DOMPurify.sanitize(htmlContent);
  // const textContent = sanitizedHtmlContent.replace(/<[^>]+>/g, "");
  const [file, setFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [error, setError] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [docHtml, setDocHtml] = useState<FileChild[]>([]);

  useEffect(() => {
    if (contractData) {
      let fields = "";
      let docGeneration = [
        new Paragraph({
          text: `Category: ${categoryName}`,
          heading: HeadingLevel.HEADING_1,
        }),
        new Paragraph({
          text: `Contract Name: ${contractData?.name}`,
          heading: HeadingLevel.HEADING_2,
        }),
      ];
      contractData.fields.forEach((field, index) => {
        fields += `<article style="display: flex; font-size: 14px !important; gap: 8px;">
          <span style="font-weight: 600;">${index + 1} -</span>
          <div style="gap: 4px; display:flex; flex-direction: column;">
            <h3 style="font-weight: 600;">${field.name}</h3>
            <h4 style="font-weight: 400; color: #000">${
              field.value ??
              "Não foi possível registrar informação. Favor atualizar documento"
            }</h4>
          </div>
        </article>`;
        docGeneration.push(
          new Paragraph({
            text: `${index + 1} -${field.name}`,
            heading: HeadingLevel.HEADING_3,
          })
        );
        if (field.value) {
          docGeneration.push(
            new Paragraph({
              text: field.value,
              heading: HeadingLevel.HEADING_3,
            })
          );
        }
      });

      const html = `<section style="padding: 16px; display: flex; flex-direction: column; align-items: start; font-family: 'Inter', sans-serif;">
        <h1 style="font-size: 24px; font-weight: 700; padding-bottom: 8px;">${categoryName}</h1>
        <h2 style="font-size: 18px; font-weight: 600; padding-bottom: 4px;">${contractData.name}</h2>
        ${fields}
      </section>`;
      setDocHtml(docGeneration);
      setHtmlContent(DOMPurify.sanitize(html));
    }
  }, [contractData, categoryName]);
  //   if (instance.loading) return <div>Loading ...</div>;

  // if (instance.error) return <div>Something went wrong: {error}</div>;

  const downloadWord = () => {
    const pdfAndDoc = PdfAndDocFormat(contractData, categoryName);
    const doc = new Document({
      sections: [
        {
          children: pdfAndDoc.docx,
        },
      ],
    });
    // ,
    // new Paragraph({
    //   children: contractData?.fields.map((field, index) => {
    //     return new Paragraph({
    //       children: [
    //         new TextRun({ text: `${index + 1} - `, bold: true }),
    //         new TextRun({ text: `${field.name}: `, bold: true }),
    //         new TextRun({ text: `${field.value}` }),
    //       ],
    //     });
    //   }),
    // }),

    Packer.toBlob(doc).then((blob) => {
      FileSaver.saveAs(blob, `${contractData?.name}.docx`);
    });
  };

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleConfirm = () => {
    // Lógica para confirmar a exclusão
    console.log("Relatório excluído");
    onDeleteContract();
    handleCloseModal();
    onClose();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      setFile(selectedFile);

      if (
        selectedFile.type === "application/pdf" ||
        selectedFile.type === ".pdf" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        onRefresh(contractData?.id ?? 0, selectedFile);
        onClose();
      } else {
        setError("Apenas arquivos PDF ou DOCX são permitidos.");
      }
    }
  };

  const openFileDialog = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const getPdfHtml = () => {
    return PdfAndDocFormat(contractData, categoryName).pdfHtml;
  };

  return (
    <Modal open={open} onClose={onClose} className="!z-[15500]">
      <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] bg-lex-white-2 w-4/5 p-2 rounded h-full max-h-[90vh]">
        <ErrorModal
          open={!!error}
          onClose={() => setError("")}
          errorMessage={error}
        />
        <ConfirmationModal
          open={modalOpen}
          onClose={handleCloseModal}
          onConfirm={handleConfirm}
        />
        <div className="flex flex-col items-center h-full">
          <div className="flex justify-between bg-white w-full p-3">
            <h2 className="text-base font-medium">Relatório</h2>
            <div className="flex gap-x-2">
              <span className="text-lex-red text-base font-semibold">
                Fechar
              </span>
              <SvgIcon className="cursor-pointer" onClick={onClose}>
                <CloseIcon />
              </SvgIcon>
            </div>
          </div>
          <section className="w-full md:max-w-[80%] h-[80%] mt-4">
            <div className="bg-white  w-full h-full overflow-y-auto">
              <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
              {/* <div
                style={{
                  padding: "1rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "start",
                  fontFamily: `"Inter", sans-serif`,
                }}
              >
                <h1
                  style={{
                    fontSize: "24px",
                    fontWeight: "700",
                    paddingBottom: "8px",
                  }}
                >
                  CategoryName
                </h1>
                <h2
                  style={{
                    fontSize: "18px",
                    fontWeight: "600",
                    paddingBottom: "0.25rem",
                  }}
                >
                  ContractName
                </h2>
                <div
                  style={{
                    display: "flex",
                    fontSize: "14px !important",
                    gap: "0.5rem",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>1 -</span>
                  <div style={{ gap: "0.5rem" }}>
                    <h3 style={{ fontWeight: "600" }}>Nome do contrato</h3>
                    <p style={{ fontWeight: "400" }}>
                      Lorem ipsum dlor sit amet, consectetur adipiscing elit.
                    </p>
                  </div>
                </div>
              </div> */}
            </div>
          </section>
          <DialogActions
            className="flex mt-4"
            style={{ justifyContent: "center" }}
          >
            <Button
              variant="contained"
              color="success"
              endIcon={<PencilIcon />}
              onClick={openFileDialog}
            >
              Atualizar análise
            </Button>
            <input
              type="file"
              accept=".pdf, .docx"
              className="hidden"
              ref={fileInputRef}
              onChange={handleFileChange}
            />
            <Button
              variant="contained"
              color="error"
              endIcon={<DeleteIcon />}
              onClick={handleOpenModal}
            >
              Excluir
            </Button>

            {contractData?.status !== "FINISHED" ? (
              <Button
                variant="contained"
                className="!bg-lex-gray-2 hover:!bg-lex-gray-2 !text-white"
                endIcon={<PdfIcon className="fill-white" />}
                disabled={contractData?.status !== "FINISHED"}
              >
                Download PDF
              </Button>
            ) : (
              <PDFDownloadLink
                document={<PdfDocument htmlContent={getPdfHtml()} />}
                fileName={`${contractData.name}.pdf`}
              >
                <Button
                  variant="contained"
                  className="bg-lex-yellow hover:bg-lex-yellow"
                  endIcon={<PdfIcon className="fill-white" />}
                >
                  Download PDF
                </Button>
              </PDFDownloadLink>
            )}

            <Button
              variant="contained"
              className={` ${
                contractData?.status !== "FINISHED"
                  ? "!bg-lex-gray-2 hover:!bg-lex-gray-2 !text-white"
                  : "bg-lex-lilac hover:bg-lex-lilac !text-white"
              }`}
              endIcon={<DocIcon className="fill-white" />}
              onClick={downloadWord}
              disabled={contractData?.status !== "FINISHED"}
            >
              Download Word
            </Button>
          </DialogActions>
        </div>
      </div>
    </Modal>
  );
};
