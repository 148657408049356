import { SvgIcon } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../shared/assets/icons/plus-circle.svg";
import { ReportsTable } from "./reports-table";
import { useEffect, useState } from "react";
import apiBase from "../../shared/services/api";
import { ReportModel } from "../../shared/models/register.models";

export const Reports = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [reportsDatas, setReportDate] = useState<ReportModel[]>([]);

  const goToAddContract = () => {
    navigate(`/app/create-report`);
  };

  useEffect(() => {
    const getReportData = async () => {
      try {
        setLoading(true);
        await apiBase
          .get(`/reports`)
          .then((response: { data: { data: ReportModel[] } }) => {
            setReportDate(response.data.data);
            setLoading(false);
            setError("");
          })
          .catch((error: any) => {
            setLoading(false);
            setError(error.message || "Erro ao criar relatorio");
          });

        //await getReportCategories(params.reportId ?? "");
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };
    getReportData();
  }, []);

  const onRefresh = async () => {
    try {
      setLoading(true);
      await apiBase
        .get(`/reports`)
        .then((response: { data: { data: ReportModel[] } }) => {
          setReportDate(response.data.data);
          setLoading(false);
          setError("");
        })
        .catch((error: any) => {
          setLoading(false);
          setError(error.message || "Erro ao criar relatorio");
        });

      //await getReportCategories(params.reportId ?? "");
    } catch (err: any) {
      setError(err.message);
      setLoading(false);
    }
  };

  return (
    <section className="bg-white">
      <div className=" flex justify-between items-baseline p-4">
        <h2 className="text-2xl font-semibold">Relatórios</h2>
        <button
          className="ml-2 plus-icon px-2 py-1 border-lex-secondary border border-solid text-lex-secondary rounded mt-4 hover:bg-lex-secondary hover:text-white "
          onClick={goToAddContract}
        >
          Novo Relatório
          <SvgIcon className="ml-2 mt-0.5">
            <PlusIcon />
          </SvgIcon>
        </button>
      </div>

      <div className="p-4">
        <ReportsTable
          reportsDatas={reportsDatas}
          onRefresh={onRefresh}
        ></ReportsTable>
      </div>
    </section>
  );
};
