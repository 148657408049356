import { BrowserRouter } from "react-router-dom";
import { AppRoutes } from "./routes";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { MyTheme } from "./shared/themes/theme";

export const App = () => {
  return (
    <ThemeProvider theme={MyTheme}>
      <BrowserRouter>
        <AppRoutes></AppRoutes>
      </BrowserRouter>
      <CssBaseline />
    </ThemeProvider>
  );
};
