import { useCallback, useEffect, useState } from "react";
import { BreadcrumbComponent } from "../../shared/components/breadcrumb";
import { ReportsHeader } from "../../shared/components/reports-header";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  SvgIcon,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import { ReactComponent as FileUploadIcona } from "../../shared/assets/icons/file-upload.svg";
import { uploadContract } from "../../shared/services/reportsService";
import { ErrorModal } from "../../shared/components/error-modal";
import apiBase from "../../shared/services/api";
import { ReportModel } from "../../shared/models/register.models";

export const UploadContract = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [reportData, setReportData] = useState<{
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
    categoryName?: string;
    typeName?: string | null;
  }>();

  const onDrop = useCallback(
    async (acceptedFiles: File[], fileRejections: any[]) => {
      if (fileRejections.length > 0) {
        setError("Apenas arquivos PDF ou DOCX são permitidos.");
        return;
      }
      setLoading(true);
      console.log(acceptedFiles);

      // setTimeout(() => {
      //   setLoading(false);
      //   navigate(`/app/report-resume/${params.reportId}`);
      // }, 1000);

      const formData = new FormData();
      for (let i = 0; i < acceptedFiles.length; i++) {
        formData.append("files", acceptedFiles[i]);
      }

      setLoading(true);
      const paramToSend = params?.typeId
        ? {
            category: params.categoryId,
            type: params.typeId,
          }
        : {
            category: params.categoryId,
          };
      try {
        await apiBase
          .post(`report/${params.reportId}/contracts`, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            params: paramToSend,
          })
          .then(() => {
            setLoading(false);
            navigate(`/app/report-resume/${params.reportId}`);
          });
      } catch (error) {
        console.error("Erro ao fazer upload:", error);
        setError("Erro ao fazer upload");
      } finally {
        setLoading(false);
      }
      // try {
      //   const data = await uploadContract(acceptedFiles);
      //   setLoading(false);
      //   console.log('Upload bem-sucedido:', data);
      // } catch (error) {
      //     setLoading(false);
      //   console.error('Erro ao fazer upload:', error);
      // }
    },
    []
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [],
    },
  });

  useEffect(() => {
    const getReportData = async () => {
      try {
        if (params.reportId && params.categoryId) {
          const getCategory = async () => {
            await apiBase
              .get(`/report/category/${params.categoryId}`)
              .then(({ data: response }) => {
                const typeName = response.types.find(
                  (type: any) => type.id === params.typeId
                )?.name;
                setReportData({
                  ...(reportData as any),
                  categoryName: response.name,
                  typeName: typeName,
                });
                console.log(response);
              });
          };

          await apiBase
            .get(`/report/${params.reportId}`)
            .then((response: { data: ReportModel }) => {
              setReportData({
                ...response.data,
                typeName: "Teste",
                categoryName: "category",
              });
              getCategory();
              setLoading(false);
              setError("");
            })
            .catch((error: any) => {
              setLoading(false);
              setError(error.message || "Erro ao criar relatorio");
            });
        } else {
          navigate("/app/reports");
        }

        //await getReportCategories(params.reportId ?? "");

        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };
    getReportData();
  }, [navigate, params.categoryId, params.reportId]);
  return (
    <section>
      <ErrorModal
        open={!!error}
        onClose={() => setError("")}
        errorMessage={error}
      />
      {loading && (
        <div className="absolute h-screen w-full left-0 top-0 z-[99999] flex justify-center items-center bg-lex-gray-3 bg-opacity-50">
          <CircularProgress
            className="text-lex-primary !w-52 !h-52"
            color="primary"
          />
        </div>
      )}
      <ReportsHeader reportName={reportData?.name ?? ""}></ReportsHeader>
      <BreadcrumbComponent
        breadcrumbs={[
          "Resumo do relatório",
          "Adicionar novo contrato",
          "Upload de contrato",
        ]}
      ></BreadcrumbComponent>

      <h2 className="text-2xl font-semibold">Upload de contrato</h2>
      <h3 className="text-base mb-6">
        Realize o upload dos arquivos e aguarde.
      </h3>
      <h3 className="text-base mb-6 font-semibold">
        Tipo de contrato:{" "}
        <span className="font-normal">
          {reportData?.categoryName}{" "}
          {reportData?.typeName ? "- " + reportData?.typeName : ""}
        </span>
      </h3>

      <Box
        {...getRootProps()}
        sx={{ padding: "20px", cursor: "pointer" }}
        className="border-2 border-lex-gray border-dashed h-96"
      >
        <input {...getInputProps()} />
        <div className="flex flex-col items-center justify-center h-full">
          <SvgIcon className="!w-20 !h-20">
            <FileUploadIcona />
          </SvgIcon>
          <h2 className="text-lex-primary text-sm font-semibold mb-4 mt-2">
            Arraste e solte seu(s) arquivo(s) aqui
          </h2>
          <span className="text-lex-primary text-base mb-3"> - OU -</span>
          <Button variant="contained" color="primary">
            Procurar arquivos
          </Button>
        </div>
      </Box>
    </section>
  );
};
