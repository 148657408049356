import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  SvgIcon,
} from "@mui/material";
import { ReactComponent as ExclamationIcon } from "../assets/icons/exclamation-mark.svg";
interface ConfirmationModalProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="!z-[15502]">
      <div className="flex flex-col items-center w-72 p-6 rounded">
        <div className="flex justify-center">
          <SvgIcon className=" h-24 flex w-full m-auto content-center">
            <ExclamationIcon className="mr-2 w-24 h-24" />
          </SvgIcon>
        </div>
        <h2 className="text-[22px] font-bold">Atenção!</h2>
        <DialogContent>
          <DialogContentText className="text-center">
            Tem certeza que deseja excluir esse relatório?
          </DialogContentText>
        </DialogContent>
        <DialogActions
          className="flex flex-col"
          style={{ justifyContent: "center" }}
        >
          <Button
            variant="contained"
            className="bg-lex-yellow text-black font-semibold hover:bg-lex-yellow"
            onClick={onConfirm}
          >
            Sim, excluir
          </Button>
          <Button
            className="font-semibold bg-lex-white-2 w-full !ml-0 mt-2"
            onClick={onClose}
            style={{ color: "black" }}
          >
            Cancelar
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};
