import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
interface ErrorModalProps {
  open: boolean;
  onClose: () => void;
  errorMessage: string;
}

export const ErrorModal: React.FC<ErrorModalProps> = ({
  open,
  onClose,
  errorMessage,
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="!z-[15502]">
      <DialogTitle>Erro</DialogTitle>
      <DialogContent>
        <p>{errorMessage}</p>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          className="!border border-solid rounded border-lex-red text-lex-red"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
