import React, {
  useState,
  useRef,
  useEffect,
  MouseEvent,
  KeyboardEvent,
} from "react";
import {
  Button,
  MenuItem,
  SvgIcon,
  Paper,
  Grow,
  ClickAwayListener,
  MenuList,
  Popper,
} from "@mui/material";
import { ReportsHeader } from "../../shared/components/reports-header";
import { useNavigate, useParams } from "react-router-dom";
import { getReportCategories } from "../../shared/services/reportsService";
import { BreadcrumbComponent } from "../../shared/components/breadcrumb";
import { ReactComponent as ArrowRightIcon } from "../../shared/assets/icons/arrow-left.svg";
import apiBase from "../../shared/services/api";
import {
  CategoriesModel,
  ReportModel,
} from "../../shared/models/register.models";
import { LoadingComponent } from "../../shared/components/utils/loading-component";

export const AddNewContract = () => {
  const params = useParams();
  const [categories, setCategories] = useState<CategoriesModel[]>([]);
  const [loading, setIsLoading] = useState(true);
  const [reportData, setReportData] = useState<{
    id: number;
    name: string;
    createdAt: Date;
    updatedAt: Date;
  }>();
  const [error, setError] = useState("");

  const [openMenuId, setOpenMenuId] = useState<number | null>(null);
  const anchorRefs = useRef<(HTMLButtonElement | null)[]>([]);

  const navigate = useNavigate();

  const handleToggle = (id: number) => {
    setTimeout(() => {
      setOpenMenuId((prevOpenMenuId) => (prevOpenMenuId === id ? null : id));
    }, 100);
  };

  const handleClose = (
    event: Event | React.SyntheticEvent | null,
    categoryId?: number,
    typeId?: number
  ) => {
    if (event) {
      if (
        anchorRefs.current[openMenuId!] &&
        anchorRefs.current[openMenuId!]?.contains(event.target as HTMLElement)
      ) {
        return;
      }
    }

    setOpenMenuId(null);

    if (params?.reportId && categoryId) {
      if (typeId) {
        navigate(
          `/app/create-contract/${params?.reportId}/upload-contract/${categoryId}/${typeId}`
        );
      } else {
        navigate(
          `/app/create-contract/${params?.reportId}/upload-contract/${categoryId}`
        );
      }
    }
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenMenuId(null);
    } else if (event.key === "Escape") {
      setOpenMenuId(null);
    }
  }

  useEffect(() => {
    if (params?.reportId) {
      const getCategories = async () => {
        try {
          setIsLoading(true);
          await apiBase
            .get("/report/categories")
            .then((response: { data: { data: CategoriesModel[] } }) => {
              setCategories(response.data.data);
              setIsLoading(false);
              setError("");
            })
            .catch((error: any) => {
              setIsLoading(false);
              setError(error.message || "Erro ao criar relatorio");
            });
        } catch (err: any) {
          setError(err.response.data.message || "erro ao buscar categorias");
          setIsLoading(false);
        }
      };

      const getReport = async () => {
        setIsLoading(true);
        try {
          await apiBase
            .get(`/report/${params.reportId}`)
            .then((response: { data: ReportModel }) => {
              setReportData(response.data);
              setIsLoading(false);
              setError("");
              getCategories();
            })
            .catch((error: any) => {
              setIsLoading(false);
              setError(error.message || "Erro ao criar relatorio");
            });
        } catch (err: any) {
          setError(err.message);
          setIsLoading(false);
        }
      };

      getReport();
    }
  }, [params?.reportId]);

  return (
    <section>
      <ReportsHeader
        reportName={reportData?.name ?? "Relatório"}
      ></ReportsHeader>
      <BreadcrumbComponent
        breadcrumbs={["Resumo do relatório", "Adicionar novo contrato"]}
      ></BreadcrumbComponent>
      {loading && <LoadingComponent />}
      <h2 className="text-2xl font-semibold">
        Adicionar categoria de contrato
      </h2>
      <h3 className="text-base mb-6">
        Adicione os arquivos de acordo com os contratos abaixo:
      </h3>

      <div className="mt-1 max-w-[432px]">
        <div className="w-full">
          {categories.map((category, index) => (
            <div key={category.id} className="w-full flex justify-between">
              <Button
                ref={(el) => (anchorRefs.current[index] = el)}
                className="w-full flex justify-between px-4 py-3 min-h-14 items-center border border-lex-white-2 border-solid text-black hover:bg-lex-white-2 bg-white rounded my-2"
                aria-controls={
                  openMenuId === category.id ? "composition-menu" : undefined
                }
                aria-expanded={openMenuId === category.id ? "true" : undefined}
                aria-haspopup="true"
                onClick={() =>
                  category.types.length > 0
                    ? handleToggle(category.id)
                    : handleClose(null, category.id)
                }
              >
                <span className="text-sm font-medium text-left">
                  {category.name}
                </span>
                <SvgIcon className="ml-2 rotate-180 !w-8 !h-8">
                  <ArrowRightIcon className="!w-8 !h-8" />
                </SvgIcon>
              </Button>
              <Popper
                open={openMenuId === category.id}
                anchorEl={anchorRefs.current[index]}
                role={undefined}
                placement="right-start"
                transition
                disablePortal
                className="!ml-2 max-w-80"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom-start"
                          ? "left top"
                          : "left bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener
                        onClickAway={(event) => handleClose(event)}
                      >
                        <MenuList
                          autoFocusItem={openMenuId === category.id}
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          onKeyDown={handleListKeyDown}
                          className="!py-0"
                        >
                          <div className="bg-lex-white-2 py-1.5 px-4">
                            Adicionar tipo de contrato
                          </div>
                          {category.types.map((type) => (
                            <MenuItem
                              className="border border-lex-white-2 border-solid"
                              key={type.id}
                              onClick={(event) =>
                                handleClose(event, category.id, type.id)
                              }
                            >
                              <span className="max-w-80 text-balance">
                                {type.name}
                              </span>
                            </MenuItem>
                          ))}
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
