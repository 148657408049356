import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import DOMPurify from "dompurify";
import logo from "../assets/images/bg-login.png";

interface PdfDocumentProps {
  htmlContent: string;
}

const styles = StyleSheet.create({
  page: {
    paddingVertical: 25,
    paddingHorizontal: 50,
    fontFamily: "Helvetica",
    backgroundImage: `url(https://letsenhance.io/static/8f5e523ee6b2479e26ecc91b9c25261e/1015f/MainAfter.jpg)`,
  },
  heading1: {
    fontSize: 24,
    fontWeight: "extrabold",
    marginBottom: 8,
    wordBreak: "break-all",
  },
  heading2: {
    fontSize: 18,
    fontWeight: "extrabold",
    marginBottom: 4,
    wordBreak: "break-all",
  },
  fieldContainer: {
    marginBottom: 8,
    flexDirection: "row",
    alignItems: "flex-start",
    flexWrap: "wrap",
  },
  fieldNumber: {
    fontSize: 15,
    fontWeight: "extrabold",
    marginRight: 4,
    wordBreak: "break-all",
  },
  fieldLabelContainer: {
    flexDirection: "column",
    flex: 1,
  },
  fieldLabel: {
    fontSize: 15,
    fontWeight: "extrabold",
    wordBreak: "break-all",
    paddingBottom: 10,
  },
  fieldValue: {
    fontSize: 13,
    color: "#000",
    wordBreak: "break-all",
  },
  logoDiv: {
    marginBottom: 20,
    position: "absolute",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    height: "100%",
    width: "100%",
    marginHorizontal: "auto",
    textAlign: "center",
  },
  logo: {
    width: 115,
    height: 30,
  },
});

const PdfDocument: React.FC<PdfDocumentProps> = ({ htmlContent }) => {
  const sanitizedContent = DOMPurify.sanitize(htmlContent);
  const parser = new DOMParser();
  const doc = parser.parseFromString(sanitizedContent, "text/html");
  const sections = doc.querySelectorAll("section");
  const pages = Array.from(sections).map((sectionField, sectionIndex) => {
    const categoryName = sectionField.querySelector("h1")?.textContent;
    const contractName = sectionField.querySelector("h2")?.textContent;
    const fields = Array.from(sectionField.querySelectorAll("article")).map(
      (field, index) => {
        const number = field.querySelector("span")?.textContent;
        const label = field.querySelector("h3")?.textContent;
        const value = field.querySelector("h4")?.textContent;

        return (
          <View key={index} style={styles.fieldContainer}>
            <Text style={styles.fieldNumber}>{number}</Text>
            <View style={styles.fieldLabelContainer}>
              <Text style={styles.fieldLabel}>{label}</Text>
              <Text style={styles.fieldValue}>{value}</Text>
            </View>
          </View>
        );
      }
    );

    return (
      <Page style={styles.page}>
        <Image
          style={styles.logo}
          src="https://i.imgur.com/7TlPYVN.png"
        ></Image>
        <Text style={styles.heading1}>{categoryName}</Text>
        <Text style={styles.heading2}>{contractName}</Text>
        {fields}
      </Page>
    );
  });

  return <Document>{pages}</Document>;
};

export default PdfDocument;
