import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SvgIcon,
} from "@mui/material";
import { Link } from "react-router-dom";
import { ReactComponent as CheckIcon } from "../../../shared/assets/icons/check-lex-primary.svg";
interface RegisterFeedbackModalProps {
  open: boolean;
  onClose: () => void;
}

export const RegisterFeedbackModal: React.FC<RegisterFeedbackModalProps> = ({
  open,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent className="!w-72">
        <div className="flex flex-col items-center">
          <SvgIcon className="mr-1 !w-[70px] !h-[70px]">
            <CheckIcon className="!w-[70px] !h-[70px]" />
          </SvgIcon>
          <h1 className="text-[22px] font-bold text-center">
            Cadastro Realizado com sucesso!
          </h1>
        </div>
      </DialogContent>
      <DialogActions className="!w-72">
        <Link
          to="/login"
          className="py-2 px-3 rounded bg-lex-primary text-white w-full flex justify-center"
          onClick={onClose}
        >
          Entrar na plataforma
        </Link>
      </DialogActions>
    </Dialog>
  );
};
