import { createTheme } from "@mui/material";

export const MyTheme = createTheme({
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
  palette: {
    primary: {
      main: "#FB4E66",
      contrastText: "#FFF",
    },
    secondary: {
      main: "#2E65FF",
      contrastText: "#FFF",
    },
  },
});
