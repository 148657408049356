import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  SvgIcon,
} from "@mui/material";
import { ReportsHeader } from "../../shared/components/reports-header";
import { ReactComponent as PlusIcon } from "../../shared/assets/icons/plus-circle.svg";
import { ReactComponent as ChartIcon } from "../../shared/assets/icons/chart-pie-slice.svg";
import { useNavigate, useParams } from "react-router-dom";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ContractsTable } from "../../shared/components/constracts-table";
import { useContext, useEffect, useState } from "react";
import apiBase from "../../shared/services/api";
import { ReportModel } from "../../shared/models/register.models";
import { ContractModel } from "../../shared/models/contract.model";
import { LoadingComponent } from "../../shared/components/utils/loading-component";
import { ErrorModal } from "../../shared/components/error-modal";
import { environment } from "../../environments/environment";
import { GenerateFinalReportModal } from "./report-resume/generate-final-report-modal";
import { MyContext } from "../../shared/contexts/socket-context";
import { ReactComponent as ExclamationIcon } from "../../shared/assets/icons/exclamation-mark.svg";

export const ReportResume = () => {
  const navigate = useNavigate();
  const params = useParams();
  const goToAddContract = () => {
    navigate(`/app/create-contract/${params.reportId}`);
  };
  const [contractsData, setContractData] = useState<ContractModel[]>([]);
  const [reportData, setReportData] = useState<ReportModel | null>(null);
  const [loading, setLoading] = useState(true);
  const [showGenerateReport, setShowGenerateReport] = useState(false);
  const [error, setError] = useState("");
  const [generateReportModalOpen, setGenerateReportModalOpen] = useState(false);

  const handleOpenModal = () => {
    setGenerateReportModalOpen(true);
  };

  const handleCloseModal = () => {
    setGenerateReportModalOpen(false);
  };
  const context = useContext(MyContext);
  if (!context) {
    throw new Error("ProtectedPage must be used within a MyProvider");
  }
  const { value, setValue } = context;
  useEffect(() => {
    const getReportData = async () => {
      try {
        if (params.reportId) {
          await apiBase
            .get(`/report/${params.reportId}`)
            .then((response: { data: ReportModel }) => {
              setContractData(response.data.contracts);
              setReportData(response.data);
              setShowGenerateReport(
                response.data.contracts.every((contract) =>
                  contract.data.every((data) => data.status === "FINISHED")
                )
              );
              setLoading(false);
              setError("");
            })
            .catch((error: any) => {
              setLoading(false);
              setError(error.message || "Erro ao criar relatorio");
            });
        } else {
          navigate("/app/reports");
        }

        //await getReportCategories(params.reportId ?? "");

        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };
    console.log("Context value updated:", value);
    getReportData();
  }, [params.reportId, navigate, value]);

  const onRefresh = async (constractId: number, file: File) => {
    const getReportData = async () => {
      try {
        if (params.reportId) {
          await apiBase
            .get(`/report/${params.reportId}`)
            .then((response: { data: ReportModel }) => {
              setContractData(response.data.contracts);
              setLoading(false);
              setError("");
            })
            .catch((error: any) => {
              setLoading(false);
              setError(error.message || "Erro ao criar relatorio");
            });
        } else {
          navigate("/app/reports");
        }

        //await getReportCategories(params.reportId ?? "");

        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };
    setLoading(true);
    const formData = new FormData();

    formData.append("file", file);

    await apiBase
      .put(`/report/contract/${constractId}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        getReportData();
        setLoading(false);
        setError("");
      })
      .catch((error: any) => {
        setLoading(false);
        setError(error.message || "Erro ao atualizar arquivo");
      });
  };

  const onDelete = async (constractId: number) => {
    const getReportData = async () => {
      try {
        if (params.reportId) {
          await apiBase
            .get(`/report/${params.reportId}`)
            .then((response: { data: ReportModel }) => {
              setContractData(response.data.contracts);
              setLoading(false);
              setError("");
            })
            .catch((error: any) => {
              setLoading(false);
              setError(error.message || "Erro ao criar relatorio");
            });
        } else {
          navigate("/app/reports");
        }

        //await getReportCategories(params.reportId ?? "");

        setLoading(false);
      } catch (err: any) {
        setError(err.message);
        setLoading(false);
      }
    };
    setLoading(true);
    await apiBase
      .delete(`/report/contract/${constractId}`)
      .then(() => {
        getReportData();
        setLoading(false);
        setError("");
      })
      .catch((error: any) => {
        setLoading(false);
        setError(error.message || "Erro ao criar relatorio");
      });
  };

  return (
    <section className="h-full flex flex-col items-center justify-between">
      <div className="flex flex-col w-full">
        <ErrorModal
          open={!!error}
          onClose={() => setError("")}
          errorMessage={error}
        />
        {loading && <LoadingComponent />}
        <ReportsHeader reportName={reportData?.name ?? ""}></ReportsHeader>

        <GenerateFinalReportModal
          open={generateReportModalOpen}
          onClose={handleCloseModal}
          reportData={reportData}
        />

        <div className="mb-6 flex justify-between items-baseline w-full">
          <h2 className="text-2xl font-semibold">Resumo do Relatório</h2>{" "}
          <button
            className="ml-2 plus-icon px-2 py-1 border-lex-secondary border border-solid text-lex-secondary rounded mt-4 hover:bg-lex-secondary hover:text-white "
            onClick={goToAddContract}
          >
            Adicionar Contrato
            <SvgIcon className="ml-2 mt-0.5">
              <PlusIcon />
            </SvgIcon>
          </button>
        </div>
        {contractsData.map((contract, index) => (
          <div key={index} className="mb-8">
            <Accordion defaultExpanded={index === 0}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                className="font-normal"
              >
                <div className="flex justify-between w-full">
                  {contract.name}
                  {contract.data.find((data) => data.status === "FAILED") && (
                    <SvgIcon className="mr-2">
                      <ExclamationIcon />
                    </SvgIcon>
                  )}
                </div>
              </AccordionSummary>
              {contract.data.find((data) => data.status === "FAILED") && (
                <div className="flex flex-col m-4">
                  <span className="p-4 border border-solid border-lex-red text-lex-red rounded w-full  flex">
                    Existe(m) erro(s) no(s) arquivo(s) carregado(s). Favor
                    tentar novamente.
                  </span>
                </div>
              )}

              <AccordionDetails>
                <ContractsTable
                  contractDatas={contract.data}
                  categoryName={contract.name}
                  onDeleteContract={onDelete}
                  onRefresh={onRefresh}
                ></ContractsTable>
              </AccordionDetails>
            </Accordion>
          </div>
        ))}
      </div>

      {contractsData.length > 0 && (
        <div className="py-4">
          <Button
            className={
              showGenerateReport
                ? "bg-lex-primary text-white hover:bg-lex-primary my-4"
                : "!bg-lex-gray-2 !text-white hover:!bg-lex-gray-2 my-4"
            }
            disabled={!showGenerateReport}
            onClick={handleOpenModal}
          >
            Gerar relatório completo{" "}
            <SvgIcon className="ml-2 mt-0.5">
              <ChartIcon />
            </SvgIcon>
          </Button>
        </div>
      )}
    </section>
  );
};
