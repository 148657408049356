import { NavLink } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { ReactComponent as ReportIcon } from "../assets/icons/report-icon.svg";
import SvgIcon from "@mui/material/SvgIcon";
import { Drawer } from "@mui/material";
import { useState } from "react";

export const Sidebar = () => {
  const drawerWidth = 250;
  const [open, setOpen] = useState(true);

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="persistent"
      anchor="left"
      open={open}
    >
      <section className="fixed top-0 left-0 w-[250px] h-full bg-white border-r border-solid border-lex-white-2 shadow z-20">
        <div className="h-24 flex items-center px-6">
          <img src={logo}></img>
        </div>
        <div className="px-6  pt-6">
          <NavLink
            to="/app/reports"
            className={({ isActive, isPending, isTransitioning }) =>
              [
                "w-full flex p-2 hover:bg-lex-white rounded",
                isPending ? "pending" : "",
                isActive ? "!text-lex-primary" : "",
                isTransitioning ? "transitioning" : "",
              ].join(" ")
            }
          >
            <SvgIcon className="mr-2">
              <ReportIcon />
            </SvgIcon>
            Relatórios
          </NavLink>
        </div>
      </section>
    </Drawer>
  );
};
