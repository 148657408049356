import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as PlusIcon } from "../../shared/assets/icons/plus-circle.svg";
import { useState } from "react";
import { createReport } from "../../shared/services/reportsService";
import { useNavigate } from "react-router-dom";
import { ErrorModal } from "../../shared/components/error-modal";
import apiBase from "../../shared/services/api";
import { LoadingComponent } from "../../shared/components/utils/loading-component";
import { Button } from "@mui/material";

export const NewReport = () => {
  const [name, setName] = useState("");
  const [responseData, setResponseData] = useState(null);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    await apiBase
      .post("/report", {
        name,
      })
      .then(
        (response: {
          data: {
            id: number;
            name: string;
            createdAt: Date;
            updatedAt: Date;
          };
        }) => {
          setIsLoading(false);
          setError("");
          navigate(`/app/create-contract/${response.data.id}`);
          console.log("Relatorio criado");
        }
      )
      .catch((error: any) => {
        setIsLoading(false);
        setError(error.message || "Erro ao criar relatorio");
      });
    // try {
    //   const data = await createItem(name);
    //   setResponseData(data);
    //   navigate("/app/create-contract");
    // } catch (err: any) {
    //   setError(err.message);
    // }
  };

  return (
    <div className="p-4 bg-white rounded">
      <ErrorModal
        open={!!error}
        onClose={() => setError("")}
        errorMessage={error}
      />
      {isLoading && <LoadingComponent />}
      <h2 className="font-bold text-xl mb-3">Relatório de Diligência</h2>
      <input
        value={name}
        onChange={(e) => setName(e.target.value)}
        type="text"
        placeholder="Insira o nome do relatório"
        className="border border-solid border-lex-white-2 p-2 w-full rounded"
      />
      <div className="flex justify-end">
        <Button
          className={`ml-2  px-2 py-1  ${
            name.trim()
              ? "plus-icon border-lex-secondary border border-solid text-lex-secondary rounded mt-4 hover:bg-lex-secondary hover:text-white"
              : "plus-icon !border-lex-gray-2 border border-solid !text-lex-gray-2 rounded mt-4"
          }`}
          onClick={handleSubmit}
          disabled={!name.trim()}
        >
          Adicionar Relatório
          <PlusIcon className="ml-2 " />
        </Button>
      </div>
    </div>
  );
};
