import { HeadingLevel, ISectionOptions, Paragraph, TextRun } from "docx";
import { ContractData, ContractModel } from "../models/contract.model";
import DOMPurify from "dompurify";
import { FileChild } from "docx/build/file/file-child";

export const PdfAndDocFormat = (
  contractData: ContractData | undefined,
  categoryName: string
): { pdfHtml: string; docx: any } => {
  if (contractData) {
    let fields = "";
    let docGeneration = [
      new Paragraph({
        heading: HeadingLevel.HEADING_1,
        children: [
          new TextRun({
            text: `${categoryName}`,
            color: "000000",
            bold: true,
          }),
        ],
        spacing: { after: 400 },
      }),
    ];
    if (contractData?.name) {
      docGeneration.push(
        new Paragraph({
          heading: HeadingLevel.HEADING_2,
          children: [
            new TextRun({
              text: `${contractData?.name}`,
              color: "000000",
            }),
          ],
          spacing: { after: 300 },
        })
      );
    }
    contractData.fields.forEach((field, index) => {
      fields += `
      <article style="display: flex; font-size: 14px !important; gap: 8px;">
            <span style="font-weight: 800;">${index + 1} -</span>
            <div style="gap: 4px; display:flex; flex-direction: column;">
              <h3 style="font-weight: 800; margin: 10px; display:block;">${
                field.name
              }</h3>
              <h4 style="font-weight: 400; color: #000; padding-top:10px; margin-top: 10px;">${
                field.value ??
                "Não foi possível registrar informação. Favor atualizar documento"
              }</h4>
            </div>
          </article>`;
      docGeneration.push(
        new Paragraph({
          heading: HeadingLevel.HEADING_3,
          children: [
            new TextRun({
              text: `${index + 1} - ${field.name}`,
              color: "000000",
              bold: true,
            }),
          ],
          spacing: { after: 200 },
        })
      );
      if (field.value) {
        docGeneration.push(
          new Paragraph({
            heading: HeadingLevel.HEADING_3,
            children: [
              new TextRun({
                text: `${field.value}`,
                color: "000000",
              }),
            ],
            spacing: { after: 200 },
          })
        );
      }
    });

    const html = `<section style="width: 100%; padding: 16px; display: flex; flex-direction: column; align-items: start; font-family: 'Inter', sans-serif;">
          <h1 style="font-size: 24px; font-weight: 700; padding-bottom: 8px;">${categoryName}</h1>
          <h2 style="font-size: 18px; font-weight: 600; padding-bottom: 4px;">${contractData.name}</h2>
          ${fields}
        </section>`;
    return { pdfHtml: DOMPurify.sanitize(html), docx: docGeneration };
  } else {
    return { pdfHtml: "", docx: "" };
  }
};

export const GenerateFinalPdfAndDocFormat = (
  contractsData: ContractModel[] | undefined,
  reportName: string
): { pdfHtml: string; docx: ISectionOptions[] } => {
  let html = "";
  let docGeneration: ISectionOptions[] = [];
  contractsData?.forEach((contractData, contractIndex) => {
    if (contractData) {
      contractData.data.forEach((data, indexData) => {
        let sectionChildren: FileChild[] = [];
        if (contractIndex === 0) {
          sectionChildren.push(
            new Paragraph({
              heading: HeadingLevel.TITLE,
              children: [
                new TextRun({
                  text: `${reportName}`,
                  color: "000000",
                  bold: true,
                }),
              ],
              spacing: { after: 500 },
            })
          );
        }
        sectionChildren.push(
          new Paragraph({
            heading: HeadingLevel.HEADING_1,
            children: [
              new TextRun({
                text: `${contractData.name}`,
                color: "000000",
                bold: true,
              }),
            ],
            spacing: { after: 400 },
          })
        );
        if (data?.name) {
          sectionChildren.push(
            new Paragraph({
              heading: HeadingLevel.HEADING_2,
              children: [
                new TextRun({
                  text: `${data?.name}`,
                  color: "000000",
                }),
              ],
              spacing: { after: 300 },
            })
          );
        }
        let fields = "";
        data.fields.forEach((field, fieldIndex) => {
          fields += `<article style="display: flex; font-size: 14px !important; gap: 8px;">
                    <span style="font-weight: 600;">${fieldIndex + 1} -</span>
                    <div style="gap: 4px; display:flex; flex-direction: column;">
                      <h3 style="font-weight: 600;">${field.name}</h3>
                      <h4 style="font-weight: 400; color: #000">${
                        field.value ??
                        "Não foi possível registrar informação. Favor atualizar documento"
                      }</h4>
                    </div>
                  </article>`;
          sectionChildren.push(
            new Paragraph({
              heading: HeadingLevel.HEADING_3,
              children: [
                new TextRun({
                  text: `${fieldIndex + 1} - ${field.name}`,
                  color: "000000",
                  bold: true,
                }),
              ],
              spacing: { after: 200 },
            })
          );
          if (field.value) {
            sectionChildren.push(
              new Paragraph({
                heading: HeadingLevel.HEADING_3,
                children: [
                  new TextRun({
                    text: `${field.value}`,
                    color: "000000",
                  }),
                ],
                spacing: { after: 200 },
              })
            );
          }
        });
        html += `<section style="width: 100%; padding: 16px; display: flex; flex-direction: column; align-items: start; font-family: 'Inter', sans-serif;">
                  <h1 style="font-size: 24px; font-weight: 700; padding-bottom: 8px;">${contractData.name}</h1>
                  <h2 style="font-size: 18px; font-weight: 600; padding-bottom: 4px;">${data.name}</h2>
                  ${fields}
                </section>`;
        docGeneration.push({
          children: sectionChildren,
        });
      });
    } else {
      return { pdfHtml: "", docx: "" };
    }
  });

  return { pdfHtml: DOMPurify.sanitize(html), docx: docGeneration };
};
