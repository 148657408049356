import { createContext, useEffect, useState } from "react";
import { environment } from "../../environments/environment";

// Cria o Context
interface MyContextType {
  value:
    | {
        id: number;
        status: string;
        name: string;
        reportId: number;
      }
    | undefined;
  setValue: React.Dispatch<
    React.SetStateAction<
      | {
          id: number;
          status: string;
          name: string;
          reportId: number;
        }
      | undefined
    >
  >;
}

export const MyContext = createContext<MyContextType | undefined>(undefined);

// Cria o Provider
export const MyProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const [value, setValue] = useState<{
    id: number;
    status: string;
    name: string;
    reportId: number;
  }>();

  useEffect(() => {
    const { io } = require("socket.io-client");
    // const socket = io(environment.api);
    const socket = io(environment.api, {
      transports: ["websocket"],
      secure: true,
    });
    socket.on("connect", () => {
      console.log("socket conected");
    });
    socket.on(
      "status",
      (data: {
        id: number;
        status: string;
        name: string;
        reportId: number;
      }) => {
        setValue(data);
        //   getReportData();
        console.log("sockettttt", data);
      }
    );

    return () => {
      socket.disconnect();
    };
  }, []);
  return (
    <MyContext.Provider value={{ value, setValue }}>
      {children}
    </MyContext.Provider>
  );
};
