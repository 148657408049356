import { Badge, Menu, MenuItem, SvgIcon } from "@mui/material";
import { ReactComponent as BellIcon } from "../assets/icons/bell.svg";
import { ReactComponent as SignOutIcon } from "../assets/icons/sign-out.svg";
import { Link, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { MyContext } from "../contexts/socket-context";
export const Header = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [notifications, setNotifications] = useState<
    {
      id: number;
      status: string;
      name: string;
      reportId: number;
    }[]
  >([]);
  const navigate = useNavigate();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (notifications.length > 0) setAnchorEl(event.currentTarget);
  };
  const handleClose = (notification?: {
    id: number;
    status: string;
    name: string;
    reportId: number;
  }) => {
    setAnchorEl(null);
    if (notification?.reportId) {
      setNotifications(
        notifications.filter((item) => item.id !== notification.id)
      );
      navigate(`/app/report-resume/${notification.reportId}`);
    }
  };

  const context = useContext(MyContext);
  if (!context) {
    throw new Error("ProtectedPage must be used within a MyProvider");
  }
  const { value, setValue } = context;

  useEffect(() => {
    const repeatNotification = notifications.find(
      (notification) => value?.reportId === notification?.reportId
    );
    if (!repeatNotification && value?.status === "FINISHED") {
      setNotifications([...notifications, value]);
    }
  }, [value]);

  return (
    <header className=" w-full bg-white shadow z-10 h-20">
      <div className=" mx-auto px-4 py-4 flex justify-end items-center h-full">
        <button
          className="text-lex-gray-4"
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
        >
          <Badge badgeContent={notifications.length} color="primary">
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </Badge>
        </button>

        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={() => {
            handleClose();
          }}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {notifications.map((notification) => (
            <MenuItem
              onClick={() => handleClose(notification)}
              className="flex flex-col justify-center"
            >
              Contrato {notification.name} Atualizado.
              <span className="text-lex-primary p-2">Clique para ver</span>
            </MenuItem>
          ))}
        </Menu>
        <span className="text-lex-gray-4 mx-3">|</span>
        <Link to="/login" className="text-lex-gray-4">
          Sair
          <SvgIcon className="ml-2">
            <SignOutIcon />
          </SvgIcon>
        </Link>
      </div>
    </header>
  );
};
