import { Button, DialogActions, Modal, SvgIcon } from "@mui/material";
import { ReportModel } from "../../../shared/models/register.models";
import { GenerateFinalPdfAndDocFormat } from "../../../shared/services/utils.service";
import PdfDocument from "../../../shared/components/pdf-utils";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ReactComponent as DocIcon } from "../../../shared/assets/icons/file-doc-white.svg";
import { ReactComponent as PdfIcon } from "../../../shared/assets/icons/file-pdf-white.svg";
import { ReactComponent as CloseIcon } from "../../../shared/assets/icons/x-circle.svg";
import { Document, ISectionOptions, Packer } from "docx";
import FileSaver from "file-saver";
import { useEffect, useState } from "react";

export const GenerateFinalReportModal: React.FC<{
  reportData: ReportModel | null;
  open: boolean;
  onClose: () => void;
}> = ({ reportData, open, onClose }) => {
  const [pdfAndDoc, setPdfAndDoc] = useState<{
    pdfHtml: string;
    docx: ISectionOptions[];
  }>();
  useEffect(() => {
    setPdfAndDoc(
      GenerateFinalPdfAndDocFormat(
        reportData?.contracts,
        reportData?.name ?? ""
      )
    );
  }, [reportData?.contracts, reportData?.name]);

  const downloadWord = () => {
    const doc = new Document({
      sections: pdfAndDoc?.docx ?? [],
    });
    Packer.toBlob(doc).then((blob) => {
      FileSaver.saveAs(blob, `${reportData?.name}.docx`);
    });
  };

  return (
    <Modal open={open} onClose={onClose} className="!z-[15500]">
      <div className="absolute top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] bg-lex-white-2 w-4/5 p-2 rounded h-full max-h-[90vh]">
        <div className="flex flex-col items-center h-full">
          <div className="flex justify-between bg-white w-full p-3">
            <h2 className="text-base font-medium">Relatorio</h2>
            <div className="flex gap-x-2">
              <span className="text-lex-red text-base font-semibold">
                Fechar
              </span>
              <SvgIcon className="cursor-pointer" onClick={onClose}>
                <CloseIcon />
              </SvgIcon>
            </div>
          </div>
          <section className="w-full md:max-w-[80%] h-[80%] mt-4">
            <div className="bg-white  w-full h-full overflow-y-auto">
              <div
                dangerouslySetInnerHTML={{ __html: pdfAndDoc?.pdfHtml ?? "" }}
              />
              {/* <div
            style={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              fontFamily: `"Inter", sans-serif`,
            }}
          >
            <h1
              style={{
                fontSize: "24px",
                fontWeight: "700",
                paddingBottom: "8px",
              }}
            >
              CategoryName
            </h1>
            <h2
              style={{
                fontSize: "18px",
                fontWeight: "600",
                paddingBottom: "0.25rem",
              }}
            >
              ContractName
            </h2>
            <div
              style={{
                display: "flex",
                fontSize: "14px !important",
                gap: "0.5rem",
              }}
            >
              <span style={{ fontWeight: "600" }}>1 -</span>
              <div style={{ gap: "0.5rem" }}>
                <h3 style={{ fontWeight: "600" }}>Nome do contrato</h3>
                <p style={{ fontWeight: "400" }}>
                  Lorem ipsum dlor sit amet, consectetur adipiscing elit.
                </p>
              </div>
            </div>
          </div> */}
            </div>
          </section>
          <DialogActions
            className="flex mt-4"
            style={{ justifyContent: "center" }}
          >
            <PDFDownloadLink
              document={<PdfDocument htmlContent={pdfAndDoc?.pdfHtml ?? ""} />}
              fileName={`${reportData?.name}.pdf`}
            >
              <Button
                variant="contained"
                className="bg-lex-yellow hover:bg-lex-yellow"
                endIcon={<PdfIcon className="fill-white" />}
              >
                Download PDF
              </Button>
            </PDFDownloadLink>

            <Button
              variant="contained"
              className="bg-lex-lilac hover:bg-lex-lilac !text-white"
              endIcon={<DocIcon className="fill-white" />}
              onClick={downloadWord}
            >
              Download Word
            </Button>
          </DialogActions>
        </div>
      </div>
    </Modal>
  );
};
