import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  IconButton,
  SvgIcon,
  Tooltip,
} from "@mui/material";
import {
  Delete,
  PictureAsPdf,
  Description,
  Visibility,
  Add,
  BorderTop,
} from "@mui/icons-material";
import { ReactComponent as DeleteIcon } from "../assets/icons/trash-trasnparent.svg";
import { ReactComponent as PdfIcon } from "../assets/icons/file-pdf-white.svg";
import { ReactComponent as DocIcon } from "../assets/icons/file-doc-white.svg";
import { ReactComponent as ViewIcon } from "../assets/icons/eye-transparent.svg";
import { ReactComponent as AnalysisIcon } from "../assets/icons/analysis-icon.svg";
import { ReactComponent as CompletedIcon } from "../assets/icons/check-circle.svg";
import { ConfirmationModal } from "./confirmation-modal";
import { ViewContractModal } from "./view-contract-modal";
import { useNavigate, useParams } from "react-router-dom";
import apiBase from "../services/api";
import { ReportModel } from "../models/register.models";
import { ContractData, ContractModel } from "../models/contract.model";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PdfDocument from "./pdf-utils";
import DOMPurify from "dompurify";
import { PdfAndDocFormat } from "../services/utils.service";
import { Document, Packer } from "docx";
import FileSaver from "file-saver";
import { ReactComponent as ExclamationIcon } from "../assets/icons/exclamation-mark.svg";

export const ContractsTable: React.FC<{
  contractDatas: ContractData[];
  categoryName: string;
  onDeleteContract: (contractId: number) => void;
  onRefresh: (contractId: number, file: File) => void;
}> = ({ contractDatas, categoryName, onDeleteContract, onRefresh }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [contractDataSelected, setContractDataSelected] =
    useState<ContractData>();
  const [selectContractId, setSelectContractId] = useState(0);

  // const textContent = sanitizedHtmlContent.replace(/<[^>]+>/g, "");
  const getSanitizedHtml = (contractData: ContractData) => {
    const pdfAndDoc = PdfAndDocFormat(contractData, categoryName);
    return pdfAndDoc.pdfHtml;
  };

  const handleOpenModal = (constractId: number) => {
    setSelectContractId(constractId);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [viewModalOpen, setViewModalOpen] = useState(false);

  const handleViewOpenModal = (contractSelected: ContractData) => {
    setContractDataSelected(contractSelected);
    setViewModalOpen(true);
  };

  const handleViewCloseModal = () => {
    setViewModalOpen(false);
  };

  const handleConfirm = (contractId: number) => {
    // Lógica para confirmar a exclusão
    console.log("Relatório excluído");
    onDeleteContract(contractId);
    handleCloseModal();
  };

  const downloadWord = (contractData: ContractData) => {
    const pdfAndDoc = PdfAndDocFormat(contractData, categoryName);
    const doc = new Document({
      sections: [
        {
          children: pdfAndDoc.docx,
        },
      ],
    });

    Packer.toBlob(doc).then((blob) => {
      FileSaver.saveAs(blob, `${contractData.name}.docx`);
    });
  };

  const sortByContractType = () => {
    // Ordena os contratos por tipo de contrato
    const sortedContracts = [...contractDatas].sort((a, b) => {
      if (a.type.name < b.type.name) return -1;
      if (a.type.name > b.type.name) return 1;
      return 0;
    });

    return sortedContracts;
  };

  return (
    <section>
      <ViewContractModal
        open={viewModalOpen}
        onClose={handleViewCloseModal}
        contractData={contractDataSelected}
        categoryName={categoryName}
        onDeleteContract={() => onDeleteContract(contractDataSelected?.id ?? 0)}
        onRefresh={(constractId, file) => onRefresh(constractId, file)}
      />
      <TableContainer component={Paper} elevation={0}>
        <Table className="shadow-none">
          <TableHead>
            <TableRow>
              <TableCell className="font-semibold pl-0">
                Nome do Contrato
              </TableCell>
              <TableCell className="font-semibold">Tipo de Contrato</TableCell>
              <TableCell className="font-semibold">Status</TableCell>
              <TableCell className="font-semibold">
                <div className="flex justify-center">Excluir</div>
              </TableCell>
              <TableCell className="font-semibold">
                <div className="flex justify-center">Download PDF</div>
              </TableCell>
              <TableCell className="font-semibold">
                <div className="flex justify-center">Download Word</div>
              </TableCell>
              <TableCell className="font-semibold">
                <div className="flex justify-center">Ver Resumo</div>
              </TableCell>
            </TableRow>
          </TableHead>
          <ConfirmationModal
            open={modalOpen}
            onClose={handleCloseModal}
            onConfirm={() => handleConfirm(selectContractId)}
          />
          <TableBody>
            {sortByContractType().map((contract, index) => {
              const previousContract =
                index > 0 ? sortByContractType()[index - 1] : null;
              const addTopBorder =
                previousContract &&
                previousContract.type.name !== contract.type.name;
              const last = sortByContractType().length === index + 1;
              return (
                <TableRow
                  key={contract.id}
                  style={{
                    borderTop: addTopBorder ? "2px solid #ABABAB" : "none",
                  }}
                >
                  <TableCell
                    className="py-1 pl-0"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {contract.name}
                  </TableCell>
                  <TableCell
                    className="py-1"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {contract?.type?.name ?? categoryName}
                  </TableCell>
                  <TableCell
                    className="py-1"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    {contract.status !== "FINISHED" ? (
                      contract.status === "FAILED" ? (
                        <SvgIcon className="mr-2">
                          <ExclamationIcon />
                        </SvgIcon>
                      ) : (
                        <SvgIcon className="mr-2">
                          <AnalysisIcon />
                        </SvgIcon>
                      )
                    ) : (
                      <SvgIcon className="mr-2">
                        <CompletedIcon />
                      </SvgIcon>
                    )}
                    {contract.status !== "FINISHED"
                      ? contract.status === "FAILED"
                        ? "Erro"
                        : "Em análise"
                      : "Analisado"}
                  </TableCell>
                  <TableCell
                    className="py-1"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <div className="flex justify-center">
                      <IconButton
                        className="px-0"
                        color="error"
                        onClick={() => handleOpenModal(contract.id)}
                      >
                        <SvgIcon>
                          <DeleteIcon className="fill-lex-red" />
                        </SvgIcon>
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell
                    className="py-1"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <div className="flex justify-center">
                      {contract.status !== "FINISHED" ? (
                        <IconButton
                          className="px-0"
                          disabled={contract.status !== "FINISHED"}
                        >
                          <SvgIcon>
                            <PdfIcon
                              className={
                                contract.status !== "FINISHED"
                                  ? "fill-lex-gray-2"
                                  : "fill-lex-yellow"
                              }
                            />
                          </SvgIcon>
                        </IconButton>
                      ) : (
                        <PDFDownloadLink
                          document={
                            <PdfDocument
                              htmlContent={getSanitizedHtml(contract)}
                            />
                          }
                          fileName={contract.name + ".pdf"}
                        >
                          <IconButton
                            className="px-0"
                            disabled={contract.status !== "FINISHED"}
                          >
                            <SvgIcon>
                              <PdfIcon
                                className={
                                  contract.status !== "FINISHED"
                                    ? "fill-lex-gray-2"
                                    : "fill-lex-yellow"
                                }
                              />
                            </SvgIcon>
                          </IconButton>
                        </PDFDownloadLink>
                      )}
                    </div>
                  </TableCell>
                  <TableCell
                    className="py-1"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <div className="flex justify-center">
                      <IconButton
                        className="px-0"
                        disabled={contract.status !== "FINISHED"}
                        onClick={() => downloadWord(contract)}
                      >
                        <SvgIcon>
                          <DocIcon
                            className={
                              contract.status !== "FINISHED"
                                ? "fill-lex-gray-2"
                                : "fill-lex-lilac"
                            }
                          />
                        </SvgIcon>
                      </IconButton>
                    </div>
                  </TableCell>
                  <TableCell
                    className="py-1"
                    style={{
                      borderBottom: last
                        ? "none"
                        : "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                    <div className="flex justify-center">
                      <IconButton
                        className="px-0"
                        disabled={contract.status !== "FINISHED"}
                      >
                        <SvgIcon onClick={() => handleViewOpenModal(contract)}>
                          <ViewIcon
                            className={
                              contract.status !== "FINISHED"
                                ? "fill-lex-gray-2"
                                : "fill-lex-secondary"
                            }
                          />
                        </SvgIcon>
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </section>
  );
};
