import { Button, CircularProgress, SvgIcon } from "@mui/material";
import logo from "../../shared/assets/images/logo.svg";
import { ReactComponent as EmailIcon } from "../../shared/assets/icons/email-icon.svg";
import { ReactComponent as LockIcon } from "../../shared/assets/icons/lock-icon.svg";
import { ReactComponent as VisibilityIcon } from "../../shared/assets/icons/visibility.svg";
import { ReactComponent as VisibilityOffIcon } from "../../shared/assets/icons/visibility-off.svg";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { ErrorModal } from "../../shared/components/error-modal";
import apiBase from "../../shared/services/api";

export const LoginComponent = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setError] = useState<string>("");
  const navigate = useNavigate();
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleOpenErrorModal = (message: string) => {
    setError(message);
    setOpenErrorModal(true);
  };

  const handleCloseErrorModal = () => {
    setOpenErrorModal(false);
  };

  useEffect(() => {
    localStorage.clear();
  }, []);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    // await login(formData.email, formData.password);
    // Redirecionar para outra página, por exemplo, a página inicial
    await apiBase
      .post("/session", {
        email: formData.email,
        password: formData.password,
      })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setIsLoading(false);
        setError("");
        navigate("/app");
        console.log("Login bem-sucedido");
      })
      .catch((error: any) => {
        setIsLoading(false);
        handleOpenErrorModal(
          error.response.data.message || "Erro ao fazer login"
        );
      });
    // localStorage.setItem(
    //   "token",
    //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOjEsImlhdCI6MTcyMDI5ODQwOSwiZXhwIjoxNzIwOTAzMjA5fQ.ao64UMhzf6NOshILWZcFqMuuT_A_TdkupPiEexo6Hoo"
    // );
    // setIsLoading(false);
    // navigate("/app");
    // console.log("Login bem-sucedido");
  };

  return (
    <section className="flex w-full h-full">
      {isLoading && (
        <div className="absolute h-screen w-screen flex justify-center items-center bg-lex-gray-3 bg-opacity-50">
          <CircularProgress
            className="text-lex-primary !w-52 !h-52"
            color="primary"
          />
        </div>
      )}
      <ErrorModal
        open={openErrorModal}
        onClose={handleCloseErrorModal}
        errorMessage={errorMessage}
      />
      <div className="w-full flex justify-center h-screen md:max-w-[700px] md:h-auto items-center">
        <div className="w-96">
          <img src={logo} />
          <h1 className="text-lex-primary text-3xl font-bold pt-8 pb-4">
            Minha conta
          </h1>
          <span className="text-lg">
            Insira seus dados para ter acesso a todas as ferramentas{" "}
            <span className="font-bold">Lexyia</span>.
          </span>

          <div className="flex border border-solid border-lex-gray-2 rounded w-96 items-center p-2 bg-lex-white mt-8 mb-3">
            <SvgIcon className="mr-1">
              <EmailIcon
                className={errorMessage ? "fill-lex-red" : "fill-lex-gray-2"}
              />
            </SvgIcon>
            <div className="flex flex-col text-lex-gray-3 w-full">
              <label className="text-xs">E-mail</label>
              <input
                className="focus-within:outline-none text-base font-semibold bg-lex-white w-full"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
          </div>

          <div className="flex border border-solid border-lex-gray-2 rounded w-96 items-center p-2 bg-lex-white mb-1">
            <SvgIcon className="mr-1">
              <LockIcon
                className={errorMessage ? "fill-lex-red" : "fill-lex-gray-2"}
              />
            </SvgIcon>
            <div className="flex flex-col text-lex-gray-3 w-full">
              <label className="text-xs">Senha</label>
              <input
                className="focus-within:outline-none text-base font-semibold bg-lex-white"
                type={showPassword ? "text" : "password"}
                name="password"
                value={formData.password}
                onChange={handleChange}
              />
            </div>
            <SvgIcon
              className="mx-1 cursor-pointer"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
            </SvgIcon>
          </div>

          <span className="underline text-lex-gray-3 flex justify-end cursor-pointer">
            Esqueci minha senha
          </span>

          <button
            onClick={handleLogin}
            className="border border-solid border-lex-gray-2 w-full text-lex-gray-3 py-3 px-4 text-center rounded ease-out duration-300 hover:bg-lex-primary hover:border-lex-primary hover:font-bold mt-8"
          >
            Entrar
          </button>

          <div className="flex items-center my-8">
            <div className="border-t border-solid border-lex-gray-3 w-full"></div>
            <span className="text-base px-3">Ou</span>
            <div className="border-t border-solid border-lex-gray-3 w-full"></div>
          </div>

          <Link
            to="/register"
            className="border flex justify-center  border-solid border-lex-gray-2 w-full text-lex-gray-3 py-3 px-4 text-center rounded ease-out duration-300 hover:bg-lex-primary hover:border-lex-primary hover:font-bold"
          >
            Criar conta
          </Link>
        </div>
      </div>
      <div className="hidden md:flex w-full bg-login h-screen bg-center bg-no-repeat bg-cover"></div>
    </section>
  );
};
