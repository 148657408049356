import { CircularProgress } from "@mui/material";

export const LoadingComponent = () => {
  return (
    <div className="absolute h-screen w-full left-0 top-0 z-[99999] flex justify-center items-center bg-lex-gray-3 bg-opacity-50">
      <CircularProgress
        className="text-lex-primary !w-52 !h-52"
        color="primary"
      />
    </div>
  );
};
