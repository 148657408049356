import { SvgIcon } from "@mui/material";
import { ReactComponent as ArrowLeft } from "../assets/icons/arrow-left.svg";
import { Link } from "react-router-dom";
export const ReportsHeader: React.FC<{ reportName: string }> = ({
  reportName,
}) => {
  return (
    <header className=" w-full bg-white shadow mb-6">
      <div className="  px-4 py-4 flex justify-between items-center h-full">
        <Link to="/app/reports" className="text-lex-primary text">
          <SvgIcon className="ml-2">
            <ArrowLeft />
          </SvgIcon>
          Voltar para Histórico de Relatórios
        </Link>
        <h2 className="text-xl mx-3 capitalize">{reportName}</h2>
      </div>
    </header>
  );
};
